button:focus  {
  z-index: 1;
}

.wrapper {
  display: flex;
  flex-direction: row;
  overflow-x: hidden;
  scroll-behavior: smooth;
  width: auto;
  height: 100%;
}

.wrapper:hover {
  overflow-x: auto;
}
